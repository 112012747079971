import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const About = ({ slice }) => {
  const {title, text, img_1, img_2, logo} = slice.primary

  return (
    <div className="">
      <img className="w-100" style={{objectFit:"cover", objectPosition: "top", maxHeight:"50vh"}} src={img_1.url}  alt={img_1.alt} title={img_1.alt}/>
      <div className={"pb-4 "}>
        <div className="container pb-5">
          {title && <h3 className="mb-5 red-bg-title" style={{maxWidth:"640px", marginTop:"-64px"}}><span>{title}</span></h3>}
          <div className="row g-4 align-items-start">
            <div className="col-12 col-lg-7 col-xl-9 d-flex flex-column justify-content-center">
              <div className="italic-as-red-text">
                <PrismicRichText field={text.richText} />
              </div>
            </div>
            <div className="col-12 col-lg-5 col-xl-3 d-none d-lg-block position-relative">
              <img className="position-absolute" style={{objectFit:"contain", height:"120px", right:"44px", top:"32px"}} src={logo.url}  alt={logo.alt} title={logo.alt}/>
              <img className="w-100" style={{objectFit:"cover", maxHeight:"640px"}} src={img_2.url}  alt={img_2.alt} title={img_2.alt}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAbout on PrismicHomepageDataBodyAbout {
    primary {
      title
      text {
        richText
      }
      img_1 {
        url
        alt
      }
      img_2 {
        url
        alt
      }
      logo {
        url
        alt
      }
    }
  }
`
