import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Pricing = ({ slice }) => {
  const { title, bg } = slice.primary
  const pricing = slice.items.map((item, index) => {
    return (
      <div className="col-12 col-lg-6 h-100" key={index} >
        <h4 className="text-white fw-bold mb-3">{item.title}</h4>
        <div className="d-flex justify-content-end" style={{marginBottom:"-20px", marginRight:"20px"}}>
          <img className="" style={{objectFit:"contain", height:"96px"}} src={item.card.url} alt={item.card.alt} title={item.card.alt}/>
        </div>
        <div className="h-100 bg-white rounded p-3">      
          <PrismicRichText field={item.services.richText} />
          <PrismicRichText field={item.achievements.richText} />
        </div>
      </div>
    )
  })

  return (
    <div className={""} style={{backgroundImage:"url(" + bg.url + ")",backgroundPosition:"center top",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <div className="py-4 homepage-cover-gradient">
        <div className="container py-5">
          <h3 className="mb-5 red-bg-title" style={{maxWidth:"640px"}}><span>{title}</span></h3>
          <div className="row g-3">
            {pricing}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPricing on PrismicHomepageDataBodyPricing {
    primary {
      title
      bg {
        url
      }
    }
    items {
      title
      card {
        url
        alt
      }
      services {
        richText
      }
      achievements {
        richText
      }
    }
  }
`
