import * as React from 'react'
import { graphql } from 'gatsby'

export const PageCover = ({ slice }) => {
  const {title, cover_img, logo, btn_label, btn_link} = slice.primary

  return (
    <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + cover_img.url + ")",backgroundPosition:"center top",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <div className="homepage-cover-gradient">
        <div className="container d-flex flex-column justify-content-center py-5" style={{minHeight:"70vh"}}>
          <img className="mb-5" style={{objectFit:"contain"}} width="132" src={logo.url} alt={logo.alt} title={logo.alt}/>
          <div className="row">
            <div className="py-5 col-12 col-xl-6 pe-3 pe-lg-5 ">
              <h1 className="text-white homepage-cover-text red-bg-title" style={{boxDecorationBreak:"clone"}}>
                <span>{title}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPageCover on PrismicHomepageDataBodyPageCover {
    primary {
      title
      logo {
        url
        alt
      }
      cover_img {
        url
      }
    }
  }
`
