import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Members = ({ slice }) => {
  const { title } = slice.primary
  const members = slice.items.map((item, index) => {
    return (
      <div className="col-12 col-lg-6" key={index} >
        <div className="h-100 bg-white d-flex">
          <img className="p-1" style={{objectFit:"cover", maxHeight:"240px", maxWidth:"50%", aspectRatio:"1"}} src={item.photo.url} alt={item.photo.alt} title={item.photo.alt}/>
          <div className="p-3 flex-fill d-flex flex-column justify-content-between align-items-center">
            <img className="mb-3" style={{objectFit:"contain", maxWidth:"80%", maxHeight:"80px"}} src={item.logo.url} alt={item.logo.alt} title={item.logo.alt}/>
            <div className="text-center">
              <div className="text-dark">{item.name}</div>
              <div><a className="text-break" href={"mailto:"+item.email}>{item.email}</a></div>
              <div><a className="text-break" href={"mailto:"+item.website}>{item.website}</a></div>
              <div><a className="text-break" href={"tel:"+item.phone}>{item.phone}</a></div>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={"bg-dark py-4"}>
      <div className="container py-5">
        <h3 className="mb-5 red-bg-title" style={{maxWidth:"640px"}}><span>{title}</span></h3>
        <div className="row g-3">
          {members}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMembers on PrismicHomepageDataBodyMembers {
    primary {
      title
    }
    items {
      photo {
        url
        alt
      }
      logo {
        url
        alt
      }
      name
      email
      website
      phone
    }
  }
`
