import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features = ({ slice }) => {
  const { title } = slice.primary
  const features = slice.items.map((item, index) => {
    return (
      <div className="col-12 col-md-6 col-lg-4" key={index} >
        <div className="text-left">
          <img className="mb-3" style={{objectFit:"contain", height:"64px"}} src={item.icon.url} alt={item.icon.alt} title={item.icon.alt}/>
          <h5 className="text-primary">{item.title}</h5>
        </div>
        <PrismicRichText field={item.text.richText} />
      </div>
    )
  })

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <h3 className="mb-5 red-bg-title" style={{maxWidth:"640px"}}><span>{title}</span></h3>
        <div className="row g-4">
          {features}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeatures on PrismicHomepageDataBodyFeatures {
    primary {
      title
    }
    items {
      icon {
        url
        alt
      }
      title
      text {
        richText
        text
      }
    }
  }
`
