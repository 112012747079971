import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const SingleText = ({ slice }) => {
  const {text} = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <h4>{text.text}</h4>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodySingleText on PrismicHomepageDataBodySingleText {
    primary {
      text {
        richText
        text
      }
    }
  }
`
