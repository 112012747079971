import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const ImageAndText = ({ slice }) => {
  const {title, text, img, logo} = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="row g-4 align-items-start">
          <div className="col-12 col-xl-7 d-flex flex-column justify-content-center">
            {title && <h3 className="mb-5 red-bg-title" style={{maxWidth:"640px"}}><span>{title}</span></h3>}
            <div className="italic-as-red-text">
              <PrismicRichText field={text.richText} />
            </div>
          </div>
          <div className="col-12 col-xl-5 d-none d-xl-block position-relative">
            <img className="position-absolute" style={{objectFit:"contain", height:"120px", right:"44px", top:"32px"}} src={logo.url}  alt={logo.alt} title={logo.alt}/>
            <img className="w-100" style={{objectFit:"cover", maxHeight:"640px"}} src={img.url}  alt={img.alt} title={img.alt}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImageAndText on PrismicHomepageDataBodyImageAndText {
    primary {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
      logo {
        url
        alt
      }
    }
  }
`
