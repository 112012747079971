import * as React from 'react'
import { Link, graphql } from 'gatsby'

export const Footer = ({ footer }) => {
  console.log('footer: ', footer)
  const {copyright,footer_links,logo} = footer;

  const footerLinks = footer
    ? footer_links.map((footerLink, index) => (
      <a key={index} href={footerLink.link.url} className="col-12 col-md-6 col-lg-4 text-decoration-none">{footerLink.label}</a>
      ))
    : null


  return (
    <footer className="mt-auto flex-column">
      {
        <>
        <div className="container py-4">
          <div className="row py-4 g-2 d-flex justify-content-center text-center">
            {footerLinks}
          </div>
        </div>
        <hr/>
        </>
      }
      <div className="container py-4 text-center">    
        <a href={"/"}><img className="" height="32" src={logo.url} alt={logo.alt} title={logo.alt}/></a>
        <div className="mt-3">{copyright.text}</div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          url
        }
      }
      logo {
        url
        alt
      }
    }
  }
`