import { PageCover } from './PageCover'
import { ImageAndText } from './ImageAndText'
import { About } from './About'
import { Features } from './Features'
import { Members } from './Members'
import { Pricing } from './Pricing'
import { Contacts } from './Contacts'
import { TitleAndText } from './TitleAndText'
import { SingleText } from './SingleText'

export const components = {
  page_cover: PageCover,
  image_and_text: ImageAndText,
  about: About,
  features: Features,
  members: Members,
  pricing: Pricing,
  contacts: Contacts,
  title_and_text: TitleAndText,
  single_text: SingleText,
}
